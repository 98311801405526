import { MatPaginatorIntl } from '@angular/material/paginator';
import { NavService } from '../app/shared/nav.service';
import { PaginatorIntl } from '../app/shared/paginator-intl';

export const environment = {
  production: true,
  templatePath: "../app/app.component.public.html",
  apiServer: {
    baseUrl: "https://api-dev.proj-001.itp-sys-service.com/api/public/v1",
    xApiKey: "fz73defnrxf3wak2znn4ckgffj4frz",
    local: false,
    auth: false,
  },
  supportUrl: "https://support-dev.proj-001.itp-sys-service.com",
  providers: [
    { provide: "ANALYTICS_ID", useValue: "G-H5ZKSR6NWJ" },
    { provide: "SCROLL_OFFSET_MOBILE", useValue: 50 },
    { provide: "SCROLL_OFFSET_DESKTOP", useValue: 145 },
    { provide: "NavService", useClass: NavService },
    { provide: MatPaginatorIntl, useValue: PaginatorIntl() },
    {
      provide: "BREADCRUMBS",
      useValue: [],
    },
  ],
};
